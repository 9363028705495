<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">财务管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">协议列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">机构名称:</span>
              <el-input
                v-model.trim="agreementComp"
                type="text"
                size="small"
                clearable
                placeholder="请输入机构名称"
              />
            </div>
            <div title="申请状态" class="searchboxItem ci-full">
              <span class="itemLabel">申请状态:</span>
              <el-select
                clearable
                size="small"
                v-model="agreementState"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in agreementStateData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="btnBox">
              <el-button class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="协议类型"
                align="left"
                prop="agreementTypeName"
                show-overflow-tooltip
              />
              <el-table-column
                label="签订机构"
                align="left"
                prop="agreementComp"
                show-overflow-tooltip
              />
              <el-table-column
                label="联系人"
                align="left"
                prop="partyAName"
                show-overflow-tooltip
              />
              <el-table-column
                label="联系电话"
                align="left"
                prop="partyAMobile"
                show-overflow-tooltip
              />
              
              <el-table-column
                label="收费标准"
                align="center"
                show-overflow-tooltip
                prop="chargesSettlement"
              />
              <el-table-column
                label="申请日期"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="申请状态"
                align="left"
                prop="agreementStateStr"
                show-overflow-tooltip
              />
              <el-table-column
                label="确认人"
                align="left"
                prop="confirmName"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.confirmName || "--" }}
                </template>
              </el-table-column>
               <el-table-column
                label="确认日期"
                align="left"
                prop="confirmTimeStr"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.confirmTimeStr || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="250"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.agreementState == '20' ||scope.row.agreementState == '30'"
                    @click="handleAgreement('confirm', scope.row)"
                    >确认</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.agreementState == '20' ||scope.row.agreementState == '30'"
                    @click="handleAgreement('reject', scope.row)"
                    >驳回</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.agreementState!= '30'"
                    @click="lookFile(scope.row.agreementId)"
                    >查看附件</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.agreementState!= '30' || scope.row.contractId"
                    @click="generateContract(scope.row.agreementId,scope.row.agreementType)"
                    >生成合同</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="70%"
      top="2%"
      @close="doCancel('ruleForm')"
    >
      <div class="ovy-a" style="height: 600px">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
         label-width="11rem"
          label-position="right"
          class="demo-ruleForm"
        >
          <el-form-item
                label="结算标准"
                prop="settleStandardType"
                class="form-item"
                v-if="ruleForm.agreementType == '20'"
              >
                <el-radio-group
                  v-model="ruleForm.settleStandardType"
                  @change="settleStandardTypeChange"
                  :disabled="seeTH"
                >
                  <el-radio label="10">按比例收费</el-radio>
                  <el-radio label="20">按人数收费</el-radio>
                  <el-radio label="30">固定费用</el-radio>
                  <el-radio label="40">其他</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                v-else-if="ruleForm.agreementType == '10'"
                label="结算标准"
                prop="settleStandardType"
                class="form-item"
              >
                <el-radio-group
                  v-model="ruleForm.settleStandardType"
                  @change="settleStandardTypeChange"
                  :disabled="seeTH"
                >
                  <el-radio label="10">技术/课程费用分开</el-radio>
                  <el-radio label="20">技术/课程费用合计</el-radio>
                  <el-radio label="30">固定费用</el-radio>
                  <el-radio label="40">其他</el-radio>
                </el-radio-group>
              </el-form-item>
               <el-form-item
                v-else
                label="结算标准"
                prop="settleStandardType"
                class="form-item"
              >
                <el-radio-group
                  v-model="ruleForm.settleStandardType"
                  @change="settleStandardTypeChange"
                  :disabled="agentdisabled"
                >
                  <el-radio label="10">按比例收费</el-radio>
                  <el-radio label="20">按最终金额收费</el-radio>
                  <el-radio label="30">按金额收费</el-radio>
                  <el-radio label="40">其他</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                prop="charge"
                class="form-item"
                v-if="
                  ruleForm.agreementType != '20' && ruleForm.agreementType != '40' &&
                    (ruleForm.settleStandardType == '10' ||
                      ruleForm.settleStandardType == '20')
                "
              >
                <el-radio-group
                  v-model="ruleForm.charge"
                  @change="settleStandardTypeChange"
                  :disabled="seeTH"
                >
                  <el-radio label="10">按比例收费</el-radio>
                  <el-radio label="20">按人数收费</el-radio>
                  <el-radio label="30">按课时</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 分开 -- 比例收费 -->
              <div
                style="display:flex;flex-direction: column;"
                v-if="
                  ruleForm.agreementType != '20' && ruleForm.agreementType != '40' &&
                    ruleForm.settleStandardType == '10' &&
                    ruleForm.charge == '10'
                "
              >
                <div style="display:flex;">
                  <el-form-item
                    prop="technologyRatio"
                    class="form-item"
                    v-if="
                      ruleForm.agreementType != '20' &&  ruleForm.agreementType != '40' &&
                        ruleForm.settleStandardType == '10' &&
                        ruleForm.charge == '10'
                    "
                  >
                    <div style="display:flex">
                      <div style="display:flex;align-items:center">
                        <span style="min-width:4rem">技术比例:</span>
                        <el-input
                          placeholder=""
                          v-model="ruleForm.technologyRatio"
                          size="small"
                          :disabled="seeTH"
                        >
                          <template slot="append">%</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item
                    prop="coursesRatio"
                    class="form-item left"
                    v-if="
                      ruleForm.agreementType != '20' &&  ruleForm.agreementType != '40' &&
                        ruleForm.settleStandardType == '10' &&
                        ruleForm.charge == '10'
                    "
                  >
                    <div
                      style="display:flex;margin-left:10px;align-items:center"
                    >
                      <span style="min-width:4rem">课程比例:</span>
                      <el-input
                        placeholder=""
                        v-model="ruleForm.coursesRatio"
                        size="small"
                        :disabled="seeTH"
                      >
                        <template slot="append">%</template>
                      </el-input>
                    </div>
                  </el-form-item>
                </div>
                <p style="margin-left:11rem">
                  计算公式:技术费用=补贴标准*技术比例*人数
                  <span>课程费用=补贴标准*课程比例*人数</span>
                </p>
              </div>
              <!-- 合计 -- 比例收费 -->
              <el-form-item
                prop="ratio"
                class="form-item"
                v-if="
                  ruleForm.agreementType != '20' && ruleForm.agreementType != '40' &&
                    ruleForm.settleStandardType == '20' &&
                    ruleForm.charge == '10'
                "
              >
                <div style="display:flex">
                  <div style="display:flex;align-items:center">
                    <span style="min-width:4rem">比例:</span>
                    <el-input
                      placeholder=""
                      v-model="ruleForm.ratio"
                      size="small"
                      :disabled="seeTH"
                    >
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:平台服务费=补贴标准*比例*人数</p>
              </el-form-item>
              <!-- 分开 -- 人数收费 -->
              <div
                style="display:flex;flex-direction: column;"
                v-if="
                  ruleForm.agreementType != '20' &&
                    ruleForm.settleStandardType == '10' &&
                    ruleForm.charge == '20'
                "
              >
                <div style="display:flex;">
                  <el-form-item
                    prop="technologyCharge"
                    class="form-item"
                    v-if="
                      ruleForm.agreementType != '20' &&
                        ruleForm.settleStandardType == '10' &&
                        ruleForm.charge == '20'
                    "
                  >
                    <div style="display:flex">
                      <div style="display:flex;align-items:center">
                        <span style="min-width:6rem">技术每人收费:</span>
                        <el-input
                          placeholder=""
                          v-model="ruleForm.technologyCharge"
                          size="small"
                          :disabled="seeTH"
                        >
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item
                    prop="coursesCharge"
                    class="form-item left"
                    v-if="
                      ruleForm.agreementType != '20' &&
                        ruleForm.settleStandardType == '10' &&
                        ruleForm.charge == '20'
                    "
                  >
                    <div style="display:flex">
                      <div
                        style="display:flex;margin-left:10px;align-items:center"
                      >
                        <span style="min-width:6rem">课程每人收费:</span>
                        <el-input
                          placeholder=""
                          v-model="ruleForm.coursesCharge"
                          size="small"
                          :disabled="seeTH"
                        >
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </div>
                <p style="margin-left:11rem">
                  计算公式:技术费用=技术每人收费*人数
                  <span>课程费用=课程每人收费*人数</span>
                </p>
              </div>
              <!-- 合计 -- 人数收费 -->
              <el-form-item
                prop="chargePerson"
                class="form-item"
                v-if="
                  ruleForm.agreementType != '20' &&
                    ruleForm.settleStandardType == '20' &&
                    ruleForm.charge == '20'
                "
              >
                <div style="display:flex">
                  <div style="display:flex;align-items:center">
                    <span style="min-width:4rem">每人收费:</span>
                    <el-input
                      placeholder=""
                      v-model="ruleForm.chargePerson"
                      size="small"
                      :disabled="seeTH"
                    >
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:平台服务费=每人收费*人数</p>
              </el-form-item>
              <!-- 分开 -- 课时收费 -->
              <div
                style="display:flex;flex-direction: column;"
                v-if="
                  ruleForm.agreementType != '20' &&
                    ruleForm.settleStandardType == '10' &&
                    ruleForm.charge == '30'
                "
              >
                <div style="display:flex;">
                  <el-form-item
                    prop="technologyChargePerson"
                    class="form-item"
                    v-if="
                      ruleForm.agreementType != '20' &&
                        ruleForm.settleStandardType == '10' &&
                        ruleForm.charge == '30'
                    "
                  >
                    <div style="display:flex">
                      <div style="display:flex;align-items:center">
                        <span style="min-width:8rem">技术每人/课时收费:</span>
                        <el-input
                          placeholder=""
                          v-model="ruleForm.technologyChargePerson"
                          size="small"
                          :disabled="seeTH"
                        >
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item
                    prop="coursesChargePerson"
                    class="form-item left"
                    v-if="
                      ruleForm.agreementType != '20' &&
                        ruleForm.settleStandardType == '10' &&
                        ruleForm.charge == '30'
                    "
                  >
                    <div style="display:flex">
                      <div
                        style="display:flex;margin-left:10px;align-items:center"
                      >
                        <span style="min-width:8rem">课程每人/课时收费:</span>
                        <el-input
                          placeholder=""
                          v-model="ruleForm.coursesChargePerson"
                          size="small"
                          :disabled="seeTH"
                        >
                          <template slot="append">元</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </div>
                <p style="margin-left:11rem">
                  计算公式:技术费用=技术每人/课时收费*人数*课时
                  <span>课程费用=课程每人/课时*人数*课时</span>
                </p>
              </div>
              <!-- 合计 -- 课时收费 -->
              <el-form-item
                prop="chargeHourPerson"
                class="form-item"
                v-if="
                  ruleForm.agreementType != '20' &&
                    ruleForm.settleStandardType == '20' &&
                    ruleForm.charge == '30'
                "
              >
                <div style="display:flex">
                  <div style="display:flex;align-items:center">
                    <span style="min-width:8rem">每人/课时收费:</span>
                    <el-input
                      placeholder=""
                      v-model="ruleForm.chargeHourPerson"
                      size="small"
                      :disabled="seeTH"
                    >
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:平台服务费=每人/课时收费*人数*课时</p>
              </el-form-item>
              <el-form-item
                prop="publicCourses"
                class="form-item"
                label="公共课是否收费"
                v-if="
                  ruleForm.agreementType != '20' && ruleForm.agreementType != '40' &&
                    ruleForm.settleStandardType == '10'
                "
              >
                <el-radio-group
                  v-model="ruleForm.publicCourses"
                  :disabled="seeTH"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </el-radio-group>
                <p>如果公共课不收费，计算时需要减去公共课部分的费用。</p>
              </el-form-item>
              <!-- 固定费用  -->
              <el-form-item
                prop="fixedCost"
                class="form-item"
                v-if="
                  ruleForm.agreementType != '20' &&  ruleForm.agreementType != '40' &&
                    ruleForm.settleStandardType == '30'
                "
              >
                <div style="display:flex">
                  <div style="display:flex;align-items:center">
                    <span style="min-width:4rem">固定费用:</span>
                    <el-input
                      placeholder=""
                      v-model="ruleForm.fixedCost"
                      size="small"
                      :disabled="seeTH"
                    >
                      <template slot="append">元/年</template>
                    </el-input>
                  </div>
                </div>
              </el-form-item>
              <!-- 付款合同 --比例收费 -->
              <el-form-item
                prop="fkcoursesRatio"
                class="form-item"
                v-if="
                  ruleForm.agreementType == '20' &&
                    ruleForm.settleStandardType == '10'
                "
              >
                <div style="display:flex">
                  <div style="display:flex;align-items:center">
                    <span style="min-width:4rem">课程比例:</span>
                    <el-input
                      placeholder=""
                      v-model="ruleForm.fkcoursesRatio"
                      size="small"
                      :disabled="seeTH"
                    >
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:国家补贴标准*比例*人数</p>
              </el-form-item>
              <el-form-item
                prop="fkchargePerson"
                class="form-item"
                v-if="
                  ruleForm.agreementType == '20' &&
                    ruleForm.settleStandardType == '20'
                "
              >
                <div style="display:flex">
                  <div style="display:flex;align-items:center">
                    <span style="min-width:4rem">每人收费:</span>
                    <el-input
                      placeholder=""
                      v-model="ruleForm.fkchargePerson"
                      size="small"
                      :disabled="seeTH"
                    >
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:每人收费*人数</p>
              </el-form-item>
              <el-form-item
                prop="fkfixedCost"
                class="form-item"
                v-if="
                  ruleForm.agreementType == '20' &&
                    ruleForm.settleStandardType == '30'
                "
              >
                <div style="display:flex">
                  <div style="display:flex;align-items:center">
                    <span style="min-width:4rem">固定费用:</span>
                    <el-input
                      placeholder=""
                      v-model="ruleForm.fkfixedCost"
                      size="small"
                      :disabled="seeTH"
                    >
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
              </el-form-item>
              <!-- 代理合同 --比例收费 -->
              <el-form-item
                prop="agentRatio"
                class="form-item"
                v-if="
                  ruleForm.agreementType == '40' &&
                    ruleForm.settleStandardType == '10'
                "
              >
                <div style="display:flex">
                  <div style="display:flex;align-items:center">
                    <span style="min-width:4rem">比例:</span>
                    <el-input
                      placeholder=""
                      v-model="ruleForm.agentRatio"
                      size="small"
                      :disabled="seeTH"
                    >
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                </div>
                <p>计算公式:应收金额合计*比例</p>
              </el-form-item>
              <el-form-item
                prop="platformMoney"
                class="form-item"
                v-if="
                  ruleForm.agreementType == '40' &&
                    ruleForm.settleStandardType == '20'
                "
              >
                <div style="display:flex">
                  <div style="display:flex;align-items:center">
                    <span style="min-width:4rem">每人收费:</span>
                    <el-input
                      placeholder=""
                      v-model="ruleForm.platformMoney"
                      size="small"
                      :disabled="seeTH"
                    >
                    <template slot="prepend">除去</template>
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
                <p>注:平台收取*元/人，其余金额均付给代理商</p>
              </el-form-item>
              <el-form-item
                prop="agentMoney"
                class="form-item"
                v-if="
                  ruleForm.agreementType == '40' &&
                    ruleForm.settleStandardType == '30'
                "
              >
               <div style="display:flex">
                  <div style="display:flex;align-items:center">
                    <span style="min-width:4rem">每人收费:</span>
                    <el-input
                      placeholder=""
                      v-model="ruleForm.agentMoney"
                      size="small"
                      :disabled="seeTH"
                    >
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
                <p>注:代理商按*元/人收费</p>
                <p>计算公式:人数*每人收费</p>
                <!-- <div style="display:flex">
                  <div style="display:flex;align-items:center">
                    <span style="min-width:4rem">金额:</span>
                    <el-input
                      placeholder=""
                      v-model="ruleForm.fkfixedCost"
                      size="small"
                      :disabled="seeTH"
                    >
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div> -->
              </el-form-item>
               <el-form-item
                prop="chargesSettlement"
                class="form-item"
                label="收费标准及结算方式"
              >
                <el-input
                  placeholder=""
                  v-model="ruleForm.chargesSettlement"
                  size="small"
                  maxlength="1000"
                  show-word-limit
                  type="textarea"
                  :disabled="seeTH"
                >
                </el-input>
                <p>展示在结算单收费标准那一栏，并且支持修改。</p>
              </el-form-item>
              <el-form-item prop="fileArr" class="form-item" label="上传附件">
                <div style="display:flex;flex-direction: column;">
                  <span
                    style="color:#f46173"
                    v-for="(item, index) in ruleForm.fileArr"
                    :key="index"
                  >
                    {{ item.appendixName }}
                    <a @click="seeExl(item.agreementUrl)" style="color: #5bb5ff"
                      >查看</a
                    >
                    <a
                      @click="reomveExl(index)"
                      style="color: #5bb5ff;margin-left:15px"
                      v-show="!seeTH  || this.$route.query.stu != 'look'"
                      >删除</a
                    >
                  </span>
                </div>

                <el-upload
                  class="upload-demo upload-btns"
                  :action="actionUrl"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-change="uploadChange"
                  :show-file-list="false"
                  :auto-upload="false"
                  :disabled="seeTH"
                >
                  <el-button size="small" class="bgc-bv" :disabled="seeTH || this.$route.query.stu == 'look'"
                    >选择合同</el-button
                  >
                </el-upload>
              </el-form-item>
        </el-form>
      </div>

      <div style="display: flex; justify-content: center; padding-top: 17px">
        <el-button @click="doCancel('ruleForm')" class="bgc-bv">取消</el-button>
        <el-button @click="getSure('ruleForm')" class="bgc-bv">确定</el-button>
      </div>
    </el-dialog>
     <!-- 审核驳回 -->
    <el-dialog
      title="驳回原因"
      :visible.sync="dialogRemarkVisible"
      width="30%"
      @close="canCle"
    >
      <div>
        <el-input
          type="textarea"
          placeholder="请输入驳回原因"
          maxlength="255"
          show-word-limit
          v-model="remarks"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="canCle" class="bgc-bv">取 消</el-button>
        <el-button @click="sure()" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
     <el-dialog
      title="合同预览"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
     <el-dialog title="查看附件" :visible.sync="filedialogVisible" width="30%">
      <el-table
        ref="multipleTable"
        :data="fileList"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="tableHeader"
        stripe
      >
         <el-table-column label="序号" align="center" type="index" />
        <el-table-column
          label="上传人"
          align="center"
          show-overflow-tooltip
          prop="createName"
        />
        <el-table-column
          label="上传时间"
          align="center"
          show-overflow-tooltip
          prop="createTimeStr"
        />
        <el-table-column
          label="文件名称"
          align="center"
          show-overflow-tooltip
          prop="appendixName"
        />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="jumpLook(scope.row)"
                >查看附件</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
export default {
  name: "operate/accountList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的联系人电话"));
      } else {
        callback();
      }
    };
    return {
      /* 机构名称 */
      agreementComp: "",
      /* 申请状态 -- 数据 */
      agreementStateData: [],
      agreementState: "", //申请状态
     
      /* 申请协议表单 -- 弹框显示||隐藏 */
      dialogVisible: false,
      dialogTitle: "确认合同",
   
      /* 表单 -- 数据字段 */
       ruleForm: {
        agreementType:'10',
        settleStandardType: "10",
        charge: "10",
        fileArr: [],
        technologyCharge: "",
        coursesCharge: "",
        technologyChargePerson: "",
        coursesChargePerson: "",
        technologyRatio: "",
        coursesRatio: "",
        fixedCost: "",
        ratio: "",
        chargePerson: "",
        chargeHourPerson: "",
        fkchargePerson: "",
        fkfixedCost: "",
        fkcoursesRatio: "",
        chargesSettlement:'',
      },
      rules: {
        settleStandardType: [
          { required: true, message: "请选择结算标准", trigger: "change" },
        ],
        technologyRatio: [
          { required: true, message: "请输入技术比例", trigger: "blur" },
        ],
        coursesRatio: [
          { required: true, message: "请输入课程比例", trigger: "blur" },
        ],
        technologyCharge: [
          { required: true, message: "请输入技术每人收费", trigger: "blur" },
        ],
        coursesCharge: [
          { required: true, message: "请输入课程每人收费", trigger: "blur" },
        ],
        technologyChargePerson: [
          {
            required: true,
            message: "请输入技术每人/课时收费",
            trigger: "blur",
          },
        ],
        coursesChargePerson: [
          {
            required: true,
            message: "请输入课程每人/课时收费",
            trigger: "blur",
          },
        ],
        ratio: [
          {
            required: true,
            message: "请输入比例",
            trigger: "blur",
          },
        ],
        chargePerson: [
          {
            required: true,
            message: "请输入每人收费",
            trigger: "blur",
          },
        ],
        chargeHourPerson: [
          {
            required: true,
            message: "请输入每人/课时收费",
            trigger: "blur",
          },
        ],
        fixedCost: [
          { required: true, message: "请输入固定费用", trigger: "blur" },
        ],
        fkcoursesRatio: [
          { required: true, message: "请输入课程比例", trigger: "blur" },
        ],
        fkchargePerson: [
          { required: true, message: "请输入每人收费", trigger: "blur" },
        ],
        fkfixedCost: [
          { required: true, message: "请输入固定费用", trigger: "blur" },
        ],
        agentRatio: [
          { required: true, message: "请输入比例", trigger: "blur" },
        ],
platformMoney: [
          { required: true, message: "请输入每人收费", trigger: "blur" },
        ],
agentMoney: [
          { required: true, message: "请输入每人收费", trigger: "blur" },
        ],
        publicCourses: [
          {
            required: true,
            message: "请选择公共课是否收费",
            trigger: "change",
          },
        ],
        chargesSettlement: [
          {
            required: true,
            message: "请输入收费标准及结算方式",
            trigger: "blur",
          },
        ],
        fileArr: [{ required: true, message: "请选择合同", trigger: "change" }],
      },
      dialogRemarkVisible:false, //驳回弹窗
      remarks:'', //驳回原因
      parameter:{},
      url:'',
      agreementStu:'',
      filedialogVisible:false, //查看附件弹窗
      dialogCert:false, //查看附件pdf弹窗
     agentdisabled:false
    };
  },
  computed: {},
  created() {
    this.getAgreementCodeValue();
  },
  methods: {
    /* 获取码值表 -- 协议类型 && 申请状态 */
    getAgreementCodeValue() {
      /* 申请状态 */
      const agreementState = this.$setDictionary("AGREEMENT_STATE", "list");
      for (const key in agreementState) {
        this.agreementStateData.push({
          value: key,
          label: agreementState[key],
        });
      }
    },

    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.agreementComp) {
        params.agreementComp = this.agreementComp;
      }
      if (this.agreementState) {
        params.agreementState = this.agreementState;
      }
      this.doFetch({
        url: "/biz/settlement/agreement/list",
        params,
        pageNum,
      });
    },
    /* 确认 || 驳回 --协议 */
    handleAgreement(agreementStu,row) {
        this.ruleForm.agreementType = row.agreementType
        if (row.agreementType == '40') {
            this.agentdisabled = true
            this.ruleForm.settleStandardType = '40'
        }
        this.ruleForm.chargesSettlement = row.chargesSettlement
        this.agreementStu  =  agreementStu;
        this.agreementId = row.agreementId;
        this.parameter = {
            agreementId:row.agreementId
        }
        if(agreementStu == 'confirm') {
            this.url = "/biz/settlement/agreement/confirmAgreement"
            this.dialogVisible = true;
            // this.doAgreementAjax(this.url,this.parameter,this.agreementStu)
        } else {
            this.dialogRemarkVisible = true;
            this.url = "/biz/settlement/agreement/rejectAgreement"
        }
    },
    doAgreementAjax(url,parameter,agreementStu) {
         this.$post(url, parameter).then(
        (res) => {
          if (res.status == 0) {
              if(agreementStu == 'reject') {
                  this.dialogRemarkVisible = false;
                this.remarks = "";
              } else {
                   this.dialogVisible = false;
                   this.ruleForm = {}
              }
              this.$message({
                  message:res.message,
                  type:'success'
              });
              this.getData(-1)
          }
        }
      );
    },
     sure() {
      if (!this.remarks) {
        this.$message({
          message: "请输入驳回理由",
          type: "warning",
        });
        return false;
      }
     this.parameter = {
            agreementId:this.agreementId,
            remarks:this.remarks
        }
      this.doAgreementAjax(this.url,this.parameter,this.agreementStu)
    },
    canCle() {
      this.remarks = "";
      this.dialogRemarkVisible = false;
    },
    getInfo(agreementId) {
      this.$post("/biz/settlement/agreement/info", { agreementId }).then(
        (res) => {
          this.ruleForm = {
            ...res.data,
          };
        }
      );
    },
      /*  合同上传*/
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";

      if (!isXLSX && !isxlsx) {
        this.$message.error("只能上传后缀是.pdf文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.fileArr.push({
            appendixName: fileName,
            agreementOss: result.data.fileKey,
            agreementUrl: result.data.fileURL,
          });
          //   this.ruleForm.fileArr2.push({
          //     fileName: fileName,
          //     fileKey: result.data.fileKey,
          //   })
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    /* 预览 */
    seeExl(fileURL) {
      this.dialogCert = true;
      console.log(fileURL);
      this.$nextTick(() => {
        pdf.embed(fileURL, "#pdf-cert");
      });
    },
    reomveExl(index) {
      this.ruleForm.fileArr.splice(index, 1);
      this.$forceUpdate();
    },
    /* 确认 -- 取消 */
    doCancel(ruleForm) {
      this.ruleForm = {
        fileArr:[],
      };
      this.$refs[ruleForm].resetFields();
      this.dialogVisible = false;
    },
    /*  -- 确认 */
    getSure(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
             let settleContent = {};
          if (this.ruleForm.agreementType != "20") {
            settleContent.charge = this.ruleForm.charge;
            settleContent.publicCourses = this.ruleForm.publicCourses;
            if (this.ruleForm.settleStandardType == "30") {
              settleContent.fixedCost = this.ruleForm.fixedCost;
            }
          }
          /* 分开 */
          if (
            this.ruleForm.settleStandardType == "10" &&
            this.ruleForm.charge == "10"
          ) {
            settleContent.technologyRatio = this.ruleForm.technologyRatio;
            settleContent.coursesRatio = this.ruleForm.coursesRatio;
          }
          if (
            this.ruleForm.settleStandardType == "10" &&
            this.ruleForm.charge == "20"
          ) {
            settleContent.technologyCharge = this.ruleForm.technologyCharge;
            settleContent.coursesCharge = this.ruleForm.coursesCharge;
          }
          if (
            this.ruleForm.settleStandardType == "10" &&
            this.ruleForm.charge == "30"
          ) {
            settleContent.technologyChargePerson = this.ruleForm.technologyChargePerson;
            settleContent.coursesChargePerson = this.ruleForm.coursesChargePerson;
          }
          /* 合并 */
          if (
            this.ruleForm.settleStandardType == "20" &&
            this.ruleForm.charge == "10"
          ) {
            settleContent.ratio = this.ruleForm.ratio;
          }
          if (
            this.ruleForm.settleStandardType == "20" &&
            this.ruleForm.charge == "20"
          ) {
            settleContent.chargePerson = this.ruleForm.chargePerson;
          }
          if (
            this.ruleForm.settleStandardType == "20" &&
            this.ruleForm.charge == "30"
          ) {
            settleContent.chargeHourPerson = this.ruleForm.chargeHourPerson;
          }
          /* 固定 */
          if (this.ruleForm.agreementType != "20") {
            if (this.ruleForm.settleStandardType == "30") {
              settleContent.fixedCost = this.ruleForm.fixedCost;
            }
          }

          if (this.ruleForm.agreementType == "20") {
            if (this.ruleForm.settleStandardType == "10") {
              settleContent.fkcoursesRatio = this.ruleForm.fkcoursesRatio;
            }
            if (this.ruleForm.settleStandardType == "20") {
              settleContent.fkchargePerson = this.ruleForm.fkchargePerson;
            }
            if (this.ruleForm.settleStandardType == "30") {
              settleContent.fkfixedCost = this.ruleForm.fkfixedCost;
            }
          }
         
          this.parameter = {
            agreementId:this.agreementId,
            fdNewAgreementAppendixList:this.ruleForm.fileArr,
             settleStandardType: this.ruleForm.settleStandardType,
             settleContent: JSON.stringify(settleContent),
        }
           this.doAgreementAjax(this.url,this.parameter,this.agreementStu)
        
        }
      });
    },
    handleDelete(agreementId) {
         this.doDel({
        url: "/biz/settlement/agreement/delete",
        msg: "确定删除该申请协议吗？",
        ps: {
          type: "post",
          data: { agreementId },
        },
      });
    },
    //查看附件
    lookFile(agreementId) {
      this.$post("/biz/settlement/agreement/checkAppendix", { agreementId }).then((ret) => {
        console.log(ret);
        this.filedialogVisible = true;
        this.fileList = ret.data || [];
      });
    },
    jumpLook(item) {
      let extension = item.appendixName.substring(
        item.appendixName.lastIndexOf(".") + 1
      );
      if (extension == "pdf" || extension == "PDF") {
       this.dialogCert = true;
        this.$nextTick(() => {
          pdf.embed(item.agreementUrl, "#pdf-cert");
        });
      } 
    },
    //生成合同
    generateContract(agreementId,agreementType) {
this.$router.push({
        path: "/web/financialSettlement/contractManagementAdd",
        query: {
          stu:'addAgreement',
          agreementId,
          agreementType,
          isApproval:true
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>

<style lang="less" scope>
.el-textarea__inner {
  min-height: 260px !important;
}
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}

</style>
<style lang="less">
.el-tooltip__popper {
  max-width: 45% !important;
}
</style>
